import "./App.css";
import BgcForm from "./Components/BgcForm";
import { BgcFormV2 } from "./Components/BgcFormV2";

function App() {
  return (
    <div className="App">
      {/* <BgcFormV2 /> */}
      <BgcForm/>
    </div>
  );
}

export default App;
